import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const carMasterDataRouter: Array<RouteRecordRaw> = [
  {
    path: '/brand',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "car-inspection-index" */ '@/views/brand/Index.vue'),
        name: 'brand',
        meta: {
          title: 'brand',
          icon: '#iconhuanfu',
          roles: ['admin'] // or you can only set roles in sub nav
        }
      }
    ]
  }
]
export default carMasterDataRouter
